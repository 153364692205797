import { useEffect, useRef, useState } from "react";
import api from "./componensts/api/api";
import List from "./componensts/List";
import Toast from "./componensts/toast/Toast";
import Clock from "./componensts/Clock";
import "./css/App.css";
import logo from "./img/logo.png";
import Socket from "./Socket";
import { io } from "socket.io-client";

const hostname = window.location.hostname;
let socketUrl = false;
if (hostname.includes("localhost")) socketUrl = "https://ws.yellgo.cloud:3200";
else if (hostname.includes(".demo.")) socketUrl = "https://ws.yellgo.cloud:3100";
else if (hostname.includes(".devaws.")) socketUrl = "https://ws.devaws.yellgo.idspace.it:3000";
else if (hostname.includes(".dev.")) socketUrl = "https://ws.devaws.yellgo.idspace.it:3000";
else socketUrl = "https://ws.yellgo.cloud";
const socket = io(socketUrl);

function App() {
	const [mandantId, setMandantId] = useState(false);
	const [settings, setSettings] = useState(false);
	const [productionsCenters, setProductionsCenters] = useState(false);
	const [shop, setShop] = useState(false);
	const [error, setError] = useState(false);
	const [orders, setOrders] = useState([]);
	const [list, setList] = useState([]);
	const [lines, setLines] = useState([1]);
	const queryParams = new URLSearchParams(window.location.search);
	const viewLines = queryParams.get("lines") ? queryParams.get("lines") : 2;
	const vmode = queryParams.get("vmode") ? queryParams.get("vmode") : false;
	const titleSize = queryParams.get("title_size") ? queryParams.get("title_size") : 40;
	const titleLine = queryParams.get("title_line") ? queryParams.get("title_line") : "normal";
	const orderSize = queryParams.get("order_size") ? queryParams.get("order_size") : 40;
	const origins = queryParams.get("origins") ? queryParams.get("origins").split(",") : false;
	const square = queryParams.get("square") ? queryParams.get("square") : 0;
	const sound = queryParams.get("sound") == 1;
	const audio = useRef(null);

	useEffect(() => {
		init();
		document.documentElement.style.setProperty("--order_size", orderSize + "px");
	}, []);

	useEffect(() => {
		if (error) setTimeout(() => setError(false), 3000);
	}, [error]);

	useEffect(() => {
		if (shop) {
			loadOrders();
		}
	}, [shop, productionsCenters]);

	useEffect(() => {
		console.log("lines se to", lines);
	}, [lines]);

	useEffect(() => {
		const c = productionsCenters ? productionsCenters.length : 1;
		if (viewLines == 1) {
			if (c == 1) {
				setLines([1]);
			}
			if (c == 2) {
				setLines([2]);
			}
			if (c == 3) {
				setLines([3]);
			}
			if (c == 4) {
				setLines([4]);
			}
			if (c == 5) {
				setLines([5]);
			}
			if (c == 6) {
				setLines([6]);
			}
		}
		if (viewLines == 2) {
			if (c == 1) {
				setLines([1]);
			}
			if (c == 2) {
				setLines([2]);
			}
			if (c == 3) {
				setLines([3]);
			}
			if (c == 4) {
				setLines([2, 2]);
			}
			if (c == 5) {
				setLines([3, 2]);
			}
			if (c == 6) {
				setLines([3, 3]);
			}
		}
	}, [productionsCenters]);

	useEffect(() => {
		if (mandantId) loadSettings();
	}, [mandantId]);

	const init = async () => {
		console.log("init");
		const mandant_id = queryParams.get("mandant_id");
		const shop_id = queryParams.get("shop_id");

		if (!mandant_id) setError("mandant_id missing");
		else if (!shop_id) setError("shop_id missing");
		else {
			api.oauth(mandant_id);
			setMandantId(mandant_id);
			loadShop(shop_id);
			loadProductionsCenters();
		}
	};

	const loadSettings = async () => {
		console.log("loadSettings");
		const res = await api.post("/settings2/get/", {
			section: ["global", "store_display"],
		});
		if (res.success == 1) setSettings(res.data);
		else setError(res.error);
	};

	const loadShop = async (shopId) => {
		console.log("loadShop");
		const res = await api.post("/shops/get/" + shopId + "/");
		if (res.success == 1) setShop(res.data);
		else setError(res.error);
	};

	const loadProductionsCenters = async () => {
		const productions_centers_ids = queryParams.get("productions_centers_ids");
		console.log("loadProductionsCenters", productions_centers_ids);
		if (productions_centers_ids) {
			const res = await api.post("/orders_display/production_centers/", {
				productions_centers_ids: productions_centers_ids.split(","),
			});
			if (res.success == 1) setProductionsCenters(res.rows);
			else setError(res.error);
		}
	};

	const loadOrders = async () => {
		console.log("loadOrders");
		const res = await api.post("/orders_display/orders/", {
			shop_id: shop.id,
			productions_centers_ids: productionsCenters
				? productionsCenters.map(function (value, index) {
						return value.id;
				  })
				: false,
			origins: origins,
		});
		if (res.success == 1) {
			setOrders(res.rows);
		} else setError(res.error);
	};

	const handleNewOrder = async (order) => {
		console.log("handleNewOrder");
		if (
			settings.store_display?.disable_popup != 1 &&
			order.status == "ready" &&
			isInOrigins(order) &&
			isInProductionCenters(order)
		) {
			setList([
				...list,
				{
					id: order.id,
					name: order.name,
					number: order.number,
					productionCenter: getProductionCenter(order),
				},
			]);
			if (sound) {
				audio.current.play().catch((err) => {
					console.log("Errore", err);
					setTimeout(function () {}, 500);
				});
			}
		}
		loadOrders();
	};

	const isInOrigins = (order) => {
		console.log("isInOrigins");
		if (!origins) return true;
		return origins.includes(order.origin);
	};

	const isInProductionCenters = (order) => {
		console.log("isInProductionCenters");
		if (!productionsCenters) return true;
		for (const p of order.products) {
			for (const productionCenters of productionsCenters) {
				if (p.production_centers_ids.includes(productionCenters.id)) return true;
			}
		}
		return false;
	};

	const getProductionCenter = (order) => {
		console.log("getProductionCenter");
		if (!productionsCenters) return false;
		for (const p of order.products) {
			for (const productionCenters of productionsCenters) {
				if (p.production_centers_ids.includes(productionCenters.id))
					return productionCenters;
			}
		}
		return false;
	};

	const getColMin = (lineIndex) => {
		if (lineIndex == 0) return 0;
		else return lines[lineIndex - 1];
	};

	const getColMax = (lineIndex) => {
		if (lineIndex == 0) return lines[0];
		else return lines[lineIndex] + lines[lineIndex - 1];
	};

	const getStyle = (line) => {
		console.log("vmode", vmode);
		if (vmode == 1) {
			return {
				gridTemplateColumns: "repeat(1, 1fr)",
				height: "100%",
			};
		} else {
			let h = "100%";
			if (lines.length == 2) h = "50%";
			return {
				gridTemplateColumns: "repeat(" + line + ", 1fr)",
				height: h,
			};
		}
	};

	const getCols = (line) => {
		const c = productionsCenters ? productionsCenters.length : 1;
		if (lines.length == 1 && c >= 5) return 1;
		return line >= 3 ? 2 : 3;
	};

	return (
		<>
			<audio ref={audio}>
				<source
					src="https://backend.dev.yellgo.cloud/sounds/sound2.wav"
					type="audio/mpeg"
				/>
				<p>Your browser does not support the audio element.</p>
			</audio>
			{mandantId && shop && (
				<Socket
					socket={socket}
					settings={settings}
					loadSettings={loadSettings}
					handleNewOrder={handleNewOrder}
					mandantId={mandantId}
					shop={shop}
					loadProductionsCenters={loadProductionsCenters}
				/>
			)}
			{viewLines <= 2 && (
				<div className="content">
					{lines &&
						lines.map((line, lineIndex) => (
							<div key={lineIndex} className="row" style={getStyle(line)}>
								{!productionsCenters && <List orders={orders} cols={3} />}
								{productionsCenters &&
									productionsCenters.map(
										(productionsCenter, i) =>
											i >= getColMin(lineIndex) &&
											i < getColMax(lineIndex) && (
												<List
													key={i}
													productionsCenter={productionsCenter}
													orders={orders}
													cols={getCols(line)}
													titleSize={titleSize}
													titleLine={titleLine}
													orderSize={orderSize}
													square={square}
													settings={settings}
												/>
											)
									)}
							</div>
						))}
				</div>
			)}
			{viewLines > 2 && (
				<div
					className="content v2"
					style={{ gridTemplateRows: "repeat(" + viewLines + ", 1fr)" }}
				>
					{[...Array(parseInt(viewLines)).keys()].map(
						(line, lineIndex) =>
							productionsCenters &&
							productionsCenters[lineIndex] && (
								<List
									key={lineIndex}
									mode="line"
									productionsCenter={productionsCenters[lineIndex]}
									orders={orders}
									cols={1}
									titleSize={titleSize}
									titleLine={titleLine}
									orderSize={orderSize}
									settings={settings}
									height={100 / parseInt(viewLines) + "%"}
								/>
							)
					)}
				</div>
			)}
			<div className="header">
				<div className="header-left">
					<div className="logo">
						<img src={logo} />
					</div>
				</div>
				<h2>{shop.name}</h2>
				<Clock />
			</div>

			<Toast
				toastList={list}
				position="center-center"
				autoDelete={true}
				autoDeleteTime={
					settings?.store_display?.popup_duration
						? parseInt(settings.store_display.popup_duration) * 1000
						: 3000
				}
			/>
			{error && <div className="error">{error}</div>}
		</>
	);
}

export default App;
